import { Injectable } from '@angular/core';
import { SousDomaine } from '../_models/sous-domaine.model';
import { Subject, Observable } from 'rxjs';
import { environment } from '../_environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SousDomaineService {

  subject = new Subject<SousDomaine[]>();
  private sousDomaines: SousDomaine[];
  private url = environment.apiUrl + 'sousDomaines.json';

  constructor(private httpClient: HttpClient) { }

  emitSubject() {
    this.subject.next(this.sousDomaines.slice());
  }

  get(id: string): SousDomaine {
    return this.sousDomaines.filter(
      x => x.id == id
    )[0];
  }

  getByDomaine(id: string): Promise<SousDomaine[]> {
    let promise = new Promise<SousDomaine[]>(
      (resolve, reject) => {
        this.httpClient.get<SousDomaine[]>(this.url).subscribe(
          response => {
            this.sousDomaines = (response === null ? [] : response);

            let subSousDomaines: SousDomaine[] = this.sousDomaines.filter(
              x => x.domaine.id == id
            );
            resolve(subSousDomaines);
          },
          error => reject()
        );
      }
    )

    return promise;
  }

  create(sousDomaine: SousDomaine): Promise<Object> {
    let promise = new Promise(
      (resolve, reject) => {
        this.httpClient.get<SousDomaine[]>(this.url).subscribe(
          response => {
            this.sousDomaines = response === null ? [] : response;

            sousDomaine.id = this.generateId(sousDomaine);
            this.sousDomaines.push(sousDomaine);

            this.registerAll().toPromise().then(() => resolve());
          },
          error => reject()
        );
      }
    )

    return promise;
  }

  update(sousDomaine: SousDomaine) {
    let index = this.sousDomaines.findIndex(
      x => x.id == sousDomaine.id
    );

    this.sousDomaines.splice(index, 1, sousDomaine);

    return this.registerAll();
  }

  delete(sousDomaine: SousDomaine) {
    let index = this.sousDomaines.findIndex(
      x => x.id == sousDomaine.id
    );

    this.sousDomaines.splice(index, 1);

    this.registerAll().subscribe(
      () => this.emitSubject(),
      (error) => console.log(error)
    );
  }

  getAll() {
    this.httpClient.get<SousDomaine[]>(this.url).subscribe(
      response => {
        this.sousDomaines = response;
        this.emitSubject();
      }
    )
  }

  registerAll(): Observable<Object> {
    return this.httpClient.put(this.url, this.sousDomaines);
  }


  generateId(sousDomaine: SousDomaine): string {
    let id: string;

    id = sousDomaine.libelle
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('-');

    return id;
  }
}

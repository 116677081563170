import { Injectable } from "@angular/core";
import { Realisation } from "../_models/realisation.model";
import { SousDomaineService } from "./sous-domaine.service";
import { Subject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../_environments/environment";

@Injectable()
export class RealisationService {

  subject = new Subject<Realisation[]>();
  private realisations: Realisation[] = [];
  private url = environment.apiUrl + 'realisations.json';

  constructor(private httpClient: HttpClient) { }

  emitSubject() {
    this.subject.next(this.realisations.slice());
  }

  getAll() {
    this.httpClient.get<Realisation[]>(this.url).subscribe(
      response => {
        this.realisations = response;
        this.emitSubject();
      }
    )
  }

  getLast(nbOfRealisations: number): Realisation[] {
    return this.realisations.slice(0, nbOfRealisations);
  }

  getBySousDomaine(sousDomaine: string): Realisation[] {
    return this.realisations.filter(
      x => x.sousDomaine.id == sousDomaine
    );
  }

  get(id: number): Realisation {
    return this.realisations.filter(
      x => x.id == id
    )[0];
  }

  create(realisation: Realisation): Promise<Object> {
    let promise = new Promise(
      (resolve, reject) => {
        this.httpClient.get<Realisation[]>(this.url).subscribe(
          response => {
            this.realisations = response === null ? [] : response;

            realisation.id = this.getNextId();
            this.realisations.push(realisation);
            
            this.registerAll().toPromise().then(() => resolve());
          },
          error => reject()
        );
      }
    )

    return promise;
  }

  update(realisation: Realisation) {
    let index = this.realisations.findIndex(
      x => x.id == realisation.id
    );

    this.realisations.splice(index, 1, realisation);

    return this.registerAll();
  }

  delete(realisation: Realisation) {
    let index = this.realisations.findIndex(
      x => x.id == realisation.id
    );

    this.realisations.splice(index, 1);

    this.registerAll().subscribe(
      () => this.emitSubject(),
      (error) => console.log(error)
    );
  }

  registerAll(): Observable<Object> {
    return this.httpClient.put(this.url, this.realisations);
  }

  getNextId(){
    let nextId: number = 0;

    this.realisations.forEach(
      realisation => nextId = Math.max(realisation.id, nextId)
    );

    return nextId + 1;
  }
}

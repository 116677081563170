import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Realisation } from '../_models/realisation.model';
import { RealisationService } from '../_services/realisation.service';
import { Subscription } from 'rxjs';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';

@Component({
  selector: 'app-realisation',
  templateUrl: './realisation.component.html',
  styleUrls: ['./realisation.component.scss']
})
export class RealisationComponent implements OnInit, OnDestroy {

  realisationSubscription: Subscription;
  showSpinner = true;

  @Input() fromAdmin: boolean;
  @Input() realisation: Realisation;

  constructor(
    private route: ActivatedRoute,
    private realisationService: RealisationService
  ) { }

  ngOnInit() {
    if (!this.fromAdmin) {
      var id: number = +(this.route.snapshot.params['id']);

      this.realisationSubscription = this.realisationService.subject.subscribe(
        realisations => {
          this.realisation = realisations.find(x => x.id == id);
          this.showSpinner = false;
        }
      )
    } else {
      this.showSpinner = false;
    }

    this.realisationService.getAll();
  }

  ngOnDestroy() {
    if(this.realisationSubscription) {
      this.realisationSubscription.unsubscribe();
    }
  }
}

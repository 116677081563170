import { RouterModule, Routes } from '@angular/router';

import { DevisComponent } from './devis/devis.component';
import { HomeComponent } from './home/home.component';
import { InterventionComponent } from './intervention/intervention.component';
import { RealisationComponent } from './realisation/realisation.component';
import { RealisationsComponent } from './realisations/realisations.component';
import { WhoComponent } from './who/who.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AuthGuard } from './_helpers/auth.guard';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ConceptDesignComponent } from './concept-design/concept-design.component';

const routes: Routes = [
    {
        path: 'extension',
        component: InterventionComponent
        //component: ComingSoonComponent
    },
    {
        path: 'extension/:sous-domaine',
        //component: RealisationsComponent
        component: ComingSoonComponent
    },
    {
        path: 'extension/:sous-domaine/:id',
        //component: RealisationComponent
        component: ComingSoonComponent
    },
    {
        path: 'renovation-interieure',
        component: InterventionComponent
        //component: ComingSoonComponent
    },
    {
        path: 'renovation-interieure/:sous-domaine',
        //component: RealisationsComponent
        component: ComingSoonComponent
    },
    {
        path: 'renovation-interieure/:sous-domaine/:id',
        //component: RealisationComponent
        component: ComingSoonComponent
    },
    {
        path: 'menuiserie-exterieure',
        component: InterventionComponent
        //component: ComingSoonComponent
    },
    {
        path: 'menuiserie-exterieure/:sous-domaine',
        //component: RealisationsComponent
        component: ComingSoonComponent
    },
    {
        path: 'menuiserie-exterieure/:sous-domaine/:id',
        //component: RealisationComponent
        component: ComingSoonComponent
    },
    {
        path: 'presentation',
        component: WhoComponent
    },
    {
        path: 'conceptDesign',
        component: ConceptDesignComponent
    },
    {
        path: 'presentation/:domaine',
        redirectTo: ':domaine'
    },
    {
        path: 'devis',
        component: DevisComponent
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        component: AdminHomeComponent
    },
    {
        path: 'admin-login',
        component: AdminLoginComponent
    },
    {
        path: 'admin-home',
        canActivate: [AuthGuard],
        component: AdminHomeComponent
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

export const appRoutingModule = RouterModule.forRoot(routes);
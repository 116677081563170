import { Domaine } from '../_models/domaine.model';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '../_environments/environment';
import { HttpClient } from '@angular/common/http';
import { SousDomaineService } from './sous-domaine.service';
import { RealisationService } from './realisation.service';

@Injectable({
  providedIn: 'root'
})
export class DomaineService {

  subject = new Subject<Domaine[]>();
  private url = environment.apiUrl + 'domaines.json';

  constructor(private httpClient: HttpClient) { }

  private domaines: Domaine[] = [
    // new Domaine(
    //   'extension',
    //   'Extension',
    //   "Agrandissement de maison traditionnelle, en bois, surélévation, création de véranda, garage, souplex, agrandissement de la surface habitable…",
    //   '../../../assets/images/renovation-interieure.jpg',
    //   'blue'),
    // new Domaine(
    //   'renovation-interieure',
    //   'Rénovation intérieure',
    //   "Rénovation de maison et d’appartement, travaux d’aménagement de combles, optimisation de l’isolation, rénovation de salle de bain, de cuisine, pose de parquet, carrelage, peinture…",
    //   '../../../assets/images/renovation-interieure.jpg',
    //   'yellow'),
    // new Domaine(
    //   'menuiserie-exterieure',
    //   'Menuiserie extérieure',
    //   "Ravalement de façade, isolation thermique par l’extérieur, travaux de maçonnerie, charpente, toiture, création de terrasse, piscine, aménagement de jardin, allées, clôtures…",
    //   '../../../assets/images/menuiserie-exterieure.jpg',
    //   'pink'),
  ]

  emitSubject() {
    this.subject.next(this.domaines.slice());
  }

  getAll() {
    this.httpClient.get<Domaine[]>(this.url).subscribe(
      response => {
        this.domaines = response;
        this.emitSubject();
      },
      error => console.log(error)
    );
  }

  get(id: string): Domaine {
    return this.domaines.filter(
      x => x.id == id
    )[0];
  }

  update(domaine: Domaine): Observable<Object> {
    let index = this.domaines.findIndex(
      x => x.id == domaine.id
    );

    this.domaines.splice(index, 1, domaine);

    return this.registerAll();
  }

  registerAll(): Observable<Object> {
    return this.httpClient.put(this.url, this.domaines);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { SousDomaine } from 'src/app/_models/sous-domaine.model';

@Component({
  selector: 'app-admin-home-sous-domaine-details',
  templateUrl: './admin-home-sous-domaine-details.component.html',
  styleUrls: ['./admin-home-sous-domaine-details.component.scss']
})
export class AdminHomeSousDomaineDetailsComponent implements OnInit {
  showUpdate: boolean = false;
  @Input() currentSousDomaine: SousDomaine;

  constructor() { }

  ngOnInit() {
  }

  update() {
    this.showUpdate = true;
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Domaine } from 'src/app/_models/domaine.model';
import { SousDomaine } from 'src/app/_models/sous-domaine.model';
import { SousDomaineService } from 'src/app/_services/sous-domaine.service';
import { Realisation } from 'src/app/_models/realisation.model';
import { DomaineService } from 'src/app/_services/domaine.service';
import { Subscription } from 'rxjs';
import { RealisationService } from 'src/app/_services/realisation.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/_components/alert-dialog/alert-dialog.component';
import { environment } from 'src/app/_environments/environment';

@Component({
  selector: 'app-admin-home-realisation-form',
  templateUrl: './admin-home-realisation-form.component.html',
  styleUrls: ['./admin-home-realisation-form.component.scss']
})
export class AdminHomeRealisationFormComponent implements OnInit, OnDestroy {
  
  form: FormGroup;
  readOnly: boolean;
  showSpinner = true;
  domaines: Domaine[];
  sousDomaines: SousDomaine[];
  domaineSubscription: Subscription;

  @Input() currentRealisation: Realisation;
  @Input() formType: string;

  afuConfig = {
    multiple: true,
    formatsAllowed: ".jpg,.jpeg,.png",
    uploadAPI:  {
      url: 'src/assets/images/upload',
      method:"POST",
      headers: {
     "Content-Type" : "text/plain;charset=UTF-8"
    //  "Authorization" : `Bearer ${token}`
      },
      responseType: 'blob',
    },
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    fileNameIndex: false,
    replaceTexts: {
      selectFileBtn: 'Selectionner image',
      resetBtn: 'Effacer',
      uploadBtn: 'Envoyer',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Envoi réussi !',
      afterUploadMsg_error: 'Echec d\'envoi',
      sizeLimit: 'Limite de poids',
    }
};

  constructor(
    private formBuilder: FormBuilder,
    private domaineService: DomaineService,
    private sousDomaineService: SousDomaineService,
    private realisationService: RealisationService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    switch(this.formType) {
      case 'edit': this.readOnly = false;break;
      case 'read': this.readOnly = true;break;
      default: this.readOnly = false;this.currentRealisation = new Realisation(-1, '',[], '', '', null, null);
    }

    this.domaineSubscription = this.domaineService.subject.subscribe(
      domaines => {
        this.domaines = domaines;
        this.initForm();
        this.showSpinner = false;
      },
      error => console.log(error)
    );

    this.domaineService.getAll();
  }

  initForm() {
    if(this.currentRealisation.sousDomaine) {
      this.updateSousDomaine(this.currentRealisation.sousDomaine.domaine.id);
    }

    this.form = this.formBuilder.group({
      hideRequired: false,
      floatLabel: 'auto',
      libelle: [this.currentRealisation.libelle, Validators.required],
      besoinClient: [this.currentRealisation.besoinClient, [Validators.required, Validators.maxLength(2000)]],
      projet: [this.currentRealisation.projet, [Validators.required, Validators.maxLength(2000)]],
      domaine: [this.currentRealisation.sousDomaine ? this.currentRealisation.sousDomaine.domaine.id : '', Validators.required],
      sousDomaine: [this.currentRealisation.sousDomaine ? this.currentRealisation.sousDomaine.id : '', Validators.required],
      dateCreation: [this.currentRealisation.dateCreation, Validators.required],
      image: [this.currentRealisation.images, Validators.required],
    });

    if(this.readOnly) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  enableForm() {
    this.form.enable();
  }

  updateSousDomaine(value) {
    this.sousDomaineService.getByDomaine(value).then(
      value => this.sousDomaines = value,
    );
  }

  submitForm() {
    let formValue = this.form.value;

    let sousDomaine: SousDomaine = this.sousDomaineService.get(formValue['sousDomaine']);

    let realisation = new Realisation(
      this.currentRealisation.id,
      formValue['libelle'],
      this.currentRealisation.images,
      formValue['besoinClient'],
      formValue['projet'],
      sousDomaine,
      formValue['dateCreation']
    );

    if(this.currentRealisation.id != -1) {
      this.realisationService.update(realisation).subscribe(
        () => {
          this.realisationService.emitSubject();
          this.openAlert('Mis à jour de la réalisation OK', 'admin');
        },
        error => console.log(error)
      );
    } else {
      this.realisationService.create(realisation).then(
        () => {
          this.realisationService.emitSubject();
          this.openAlert('Création OK', 'admin');
        },
        error => console.log(error)
      );
    }
  }

  openAlert(message: string, navigate: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {texte: message}
    });

    dialogRef.afterClosed().subscribe(
      () => this.router.navigate([navigate])
    );
  }

  upload(event) {
    console.log(event);
  }

  ngOnDestroy(): void {
    this.domaineSubscription.unsubscribe();
  }
}

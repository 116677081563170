import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  //isMobile: boolean = false;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    //this.isMobile = this.deviceService.isMobile();
  }

}

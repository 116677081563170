import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomaineService } from 'src/app/_services/domaine.service';
import { Domaine } from 'src/app/_models/domaine.model';
import { Subscription } from 'rxjs';
import { environment } from 'src/app/_environments/environment';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isMobile: boolean = false;
  domaines: Domaine[];
  domaineSubscription: Subscription;
  opened: boolean = true;
  events: string[] = [];
  
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  
  constructor(private deviceService: DeviceDetectorService, private domaineService: DomaineService) { }


  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    this.domaineSubscription = this.domaineService.subject.subscribe(
      domaines => this.domaines = domaines,
      error => console.log(error)
    );

    this.domaineService.getAll();
  }
  
  goToFacebook() {
    window.open(environment.facebook, '_blank');
  }

  goToInstagram() {
    window.open(environment.instagram, '_blank');
  }

  emitToggle() {
    this.sidenav.toggle();
  }


  ngOnDestroy() {
    this.domaineSubscription.unsubscribe();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomaineService } from '../_services/domaine.service';
import { Subscription } from 'rxjs';
import { Domaine } from '../_models/domaine.model';
import { SousDomaine } from '../_models/sous-domaine.model';
import { SousDomaineService } from '../_services/sous-domaine.service';

@Component({
  selector: 'app-who',
  templateUrl: './who.component.html',
  styleUrls: ['./who.component.scss']
})
export class WhoComponent implements OnInit, OnDestroy {

  domaineSubscription: Subscription;
  sousDomaineSubscription: Subscription;

  domaines: Domaine[];
  sousDomaines: SousDomaine[];
  
  constructor(
    private domaineService: DomaineService,
    private sousDomaineService: SousDomaineService
  ) { }

  ngOnInit() {
    this.domaineSubscription = this.domaineService.subject.subscribe(
      domaines => this.domaines = domaines,
      error => console.log(error)
    );

    this.sousDomaineSubscription = this.sousDomaineService.subject.subscribe(
      sousDomaines => this.sousDomaines = sousDomaines,
      error => console.log(error)
    );

    this.domaineService.getAll();
    this.sousDomaineService.getAll();
  }

  ngOnDestroy(): void {
    this.domaineSubscription.unsubscribe();
    this.sousDomaineSubscription.unsubscribe();
  }

}

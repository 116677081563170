import { Injectable } from '@angular/core';
import { AdminHomeComponent } from '../admin/admin-home/admin-home.component';
import { environment } from '../_environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user.model';
import { Observable } from 'rxjs';
import { sha256 } from 'js-sha256';
import { resolveReflectiveProviders } from '@angular/core/src/di/reflective_provider';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuth = false;
  private url = environment.apiUrl + 'users.json';
  private users: User[] = [
    // new User('volki', sha256.hmac(environment.sel, 'a')),
    // new User('ugur', sha256.hmac(environment.sel, 'a'))
  ];
  
  constructor(private httpClient: HttpClient) {}


  signIn(login: string, password: string): Promise<boolean>{
    return new Promise(
      (resolve, reject) => {
        this.httpClient.get<User[]>(this.url).subscribe(
          users => {
            users.forEach(
              user => {
                if(user.login === login && (user.password === sha256.hmac(environment.sel, password))) {
                  this.isAuth = true;  
                  resolve(true);
                }
              }
            )
            resolve(false);
          }
        );

      }
    );
  }

  signOut() {
    this.isAuth = false;
  }

  // create(login: string, password: string){
  //   let user: User = new User(login, sha256.hmac(environment.sel, password));

  //   this.httpClient.put(this.url, users).subscribe(
  //     response => {
  //       console.log("Création user ok");
  //     }
  //   );


  }
